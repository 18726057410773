.Upload {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  
  label{
    align-items: center;
    padding: 12px;
    background: red;
    display: table;
    color: #fff;
    /* margin-left: 700px; */
    /* padding-inline: 40px; */
     }
  
     .button {
      background-color: #4CAF50; /* Green */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      margin: 4px 2px;
      cursor: pointer;
    }
  .ref{
    margin-top: auto;
  }
  
  input[type="file"] {
    display: none;
  }