html, body {
    background-color: #222;
    width: 100%;
    height: 100%;
    /* width: auto;
    overflow: hidden; */
  }
  
  a,h1,h2,h3,h4,h5,h6 {
    color: white;
  }
  
  a:hover, a:hover > * {
    color: grey;
  }
  
  .categories--menu-container {
      position: relative;
      /* height: 100%; */
      /* height: calc(100vh - 150px); */
      width: 100%;
      padding-right: 300px;
  }
  
  ul.categories {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      list-style: none;
  }
  
  .category {
      position: relative;
  }
  
  
  /*content*/
  
  .category--content {
      position: absolute;
      top: 50px;
      white-space: nowrap;
      right: 0;
      transform: translateX(100%);
  }
  
  .category--content h2 {
      text-align: left;
      font-size: 44px;
      transform: translateX(-50px);
      text-transform: uppercase;
      font-weight: bold;
  }
  
  .category--content .project-list {
    padding: 0 0 0 30px;
  }
  
  .category--content,
  .category--content .project-list,
  .category--content .project-list li {
      text-align: left;
  }
  
  .category--name {
    position: absolute;
    white-space: nowrap;
    width: calc(100% - 5px);
    transition: all 0.5s ease;
    padding-top: 5px;
    margin-top: 15px;
    margin-right: 5px;
    border-top: 1px solid #666;
    color: #666;
  }
  
  .category--name h6 {
    font-size: 16px;
    color: inherit;
    text-transform: capitalize;
  }
  
  .focused .category--name {
      opacity: 0;
  }
  
  .projectlist--client {
    font-size: 18px;
    margin-bottom: 0px;
    -webkit-text-stroke: 0;
    text-transform: capitalize;
    font-weight: bold;
  }
  .projectlist--byline {
    font-size: 12px;
    font-family: 'Nothing You Could Do', sans-serif;
  }
  
  /*last item content*/
  
  .isLast .category--content {
      right: initial;
      left: 0;
      transform: translateX(-100%);
  }
  
  .isLast .category--content h2 {
    transform: translateX(50px);
    text-align: right;
  }
  
  .isLast .category--content .project-list {
    padding: 0 30px 0 0;
  }
  
  .isLast .category--content,
  .isLast .category--content .project-list,
  .isLast .category--content .project-list li {
    text-align: right;
  }
  
  
  /*inactive states*/
  
  li.category {
      transition: transform 500ms ease;
      position: relative;
      z-index: 1;
      list-style: none;
  }
  
  .category--content {
      text-align: right;
      z-index: -1;
      visibility: hidden;
      pointer-events: none;
  }
  
  .category--content .project-list li {
      opacity: 0;
      transition: all 300ms ease;
  }
  
  .category--image-container {
      background: #FFF;
      transition: all 500ms ease;
  }
  
  
  /*active states*/
  
  li.category.isActive {
      z-index: 99;
      list-style: none;
  }
  
  .isActive .category--content {
      z-index: 99;
      visibility: visible;
      pointer-events: all;
  }
  
  .isActive .category--content .project-list li {
      opacity: 1;
  }
  
  
  /*image states*/
  
  .category--image:hover, .category--image:focus, .focused .category--image:hover, .focused .category--image:focus {
      opacity: 1;
      cursor: pointer;
  }
  
  .category--image {
      opacity: 0.7;
      transition: opacity 500ms ease, width 500ms ease 100ms;
  }
  
  .focused .category--image {
      opacity: 0.25;
  }
  
  .focused .isActive .category--image {
      opacity: 0.75;
  }
  
  .category--closeButton {
      opacity: 0;
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: -5rem;
      transition: opacity 200ms ease;
  }
  
  .focused .isActive .category--closeButton {
    opacity: 1;
    z-index: 99;
  }
  
  .category--closeButton a {
    margin: -15px;
    padding: 15px;
  }
  
  /*hover state*/
  .category--image {
    /*transition: width 500ms ease;*/
  }
  .categories--menu-container:not(.focused) li.category:hover .category--name {
    border-color: #eee;
    color: #eee;
  }
  
  /*Nav*/
  .right-arrow {
      position: fixed;
      bottom: 50px;
      right: 50px;
      display: inline-block;
  }
  
  .left-arrow {
      position: fixed;
      bottom: 50px;
      left: 50px;
      display: inline-block;
  }
  
  .left-arrow a, .right-arrow a {
    background-color: #222;
    z-index: 9;
    position: relative;
    padding: 5px;
    font-size: 12px;
  }
  .right-arrow img, .left-arrow img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    z-index: 0;
    width: 140px;
    max-width: none;
  }
  
  .left-arrow img {
    transform: translate(-50%, -50%) scaleX(-1);
  }
  